// Home.js
import React from 'react';
import { Box } from '@mui/material';
import HeroSection from './subComponnets/HeroSection';
import InfoCardsSection from './subComponnets/InfoCardSection';
import Appointment from './subComponnets/Appointment';
import HappyPatients from './subComponnets/HappyPatients';
import OurServices from './subComponnets/OurServices';
const Home = () => {
  return (
      <Box id="home" sx={{ overflowX: 'hidden', paddingTop: 0 }}>
        <HeroSection />
        <InfoCardsSection />
        <OurServices />
        <Appointment />
        <HappyPatients />
      </Box>
   
  );
};

export default Home;
