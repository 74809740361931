import React from 'react';
import { Box, Typography, IconButton, useMediaQuery } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const TopBar = () => {
  const isMobile = useMediaQuery('(max-width:600px)'); // Adjust based on your needs

  return (
    <Box
      sx={{
        backgroundColor: '#2a5c82',
        color: '#fff',
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: isMobile ? 'center' : 'space-between',
        alignItems: 'center',
        padding: isMobile ? '16px 8px' : '8px 16px',
        textAlign: isMobile ? 'center' : 'left',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'center',
          gap: isMobile ? 1 : 2,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton color="inherit" size="small">
            <EmailIcon fontSize="small" />
          </IconButton>
          <Typography variant="body2">support@csquare.com</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton color="inherit" size="small">
            <LocationOnIcon fontSize="small" />
          </IconButton>
          <Typography variant="body2">159 Parer Road, Airport West, VIC, 3042</Typography>
        </Box>
      </Box>
      <Box sx={{ marginTop: isMobile ? 1 : 0 }}>
        <Typography variant="body2">Call Now : 433-650-188</Typography>
      </Box>
    </Box>
  );
};

export default TopBar;
