import "./App.css";
import Navbar from "./component/Navbar";
import TopBar from "./component/TopBar";
import Home from "./component/Home";
import Footer from "./component/Footer";
import ServiceDetails from "./component/ServiceDetails";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="App">
        <TopBar />
        <Navbar />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/service/:type" element={<ServiceDetails />} />
        </Routes>

        <Footer />
    </div>
  );
}

export default App;
