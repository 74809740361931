import React from 'react';
import { Box, Grid, Typography, Card, CardMedia } from '@mui/material';
import { Link } from 'react-router-dom';
import { services } from '../../constants/services';

const OurServices = () => {

    return (
        <Box sx={{ py: 5, backgroundColor: '#f5f5f5' }} id="services">
            <Typography variant="h4" align="center" gutterBottom>
                Our Services
            </Typography>
            <Grid container spacing={3} justifyContent="center">
                {services.map((service, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                        <Link to={`/service/${service.id}`} style={{ textDecoration: 'none' }}>
                            <Card sx={{ position: 'relative', overflow: 'hidden' }}>
                                <CardMedia
                                    component="img"
                                    height="200"
                                    image={service.thumbnail}
                                    alt={service.title}
                                />
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        bgcolor: 'rgba(0, 0, 0, 0.5)',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: '#fff',
                                        opacity: 0,
                                        transition: 'opacity 0.3s ease-in-out',
                                        '&:hover': { opacity: 1 },
                                    }}
                                >
                                    <Typography variant="h6">{service.heading}</Typography>
                                </Box>
                            </Card>
                        </Link>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default OurServices;
