import React, { useEffect } from "react";
import { Typography, Box, Grid, Paper } from "@mui/material";
import { services } from "../constants/services";
import { useParams } from "react-router-dom";

const ServiceDetails = () => {
  const params = useParams();
  const [service, setService] = React.useState(null);

  useEffect(() => {
    const serviceData = services.find((service) => service.id === params.type);
    setService(serviceData);
  }, [params.type]);

  if (!service) {
    return null;
  }

  return (
    <Box sx={{ maxWidth: "100%" }}>
      {/* Hero Section */}
      <Box
        sx={{
          py: 6,
          backgroundImage: `url(../${service.banner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          color: "#fff", // Primary text color
          "::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent grey overlay
            zIndex: 1,
          },
          zIndex: 2,
          textAlign: "center",
        }}
      >
        <Typography
          variant="h3"
          fontWeight="bold"
          gutterBottom
          sx={{
            position: "relative",
            zIndex: 2,
            textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)",
          }}
        >
          {service.heading}
        </Typography>
      </Box>

      {/* Why Choose Us Section */}
      <Box sx={{ py: 4, px: 2 }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={4}>
            <Box
              component="img"
              src={`../${service.thumbnail}`}
              alt="Thumbnail"
              sx={{
                width: "100%",
                borderRadius: 2,
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              gutterBottom
              sx={{ textAlign: "left" }}
            >
              {service.title}
            </Typography>
            <Typography
              variant="body1"
              sx={{ textAlign: "left" }}
              dangerouslySetInnerHTML={{ __html: service.description }}
            />
          </Grid>
        </Grid>
      </Box>

      {/* Additional Services Section */}
      {service?.serviceHeading && (
        <Box sx={{ py: 4, backgroundColor: "#f5f5f5", borderRadius: 2, px: 3 }}>
          <Typography
            variant="body1"
            fontWeight="bold"
            textAlign="center"
            gutterBottom
          >
            {service.serviceHeading}
          </Typography>
        </Box>
      )}

      {service?.services?.length > 0 && (
        <Box
          sx={{
            py: 4,
            backgroundColor: "#f5f5f5",
            borderRadius: 2,
            px: 3,
          }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            gutterBottom
            sx={{ textAlign: "left" }}
          >
            {service.heading} services include:
          </Typography>
          <Grid container spacing={3}>
            {service.services.map((service, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Paper
                  elevation={3}
                  sx={{
                    p: 3,
                    "&:hover": {
                      backgroundColor: "#86ad3a", // Green background on hover
                      color: "#ffffff", // Optional: Change text color to white for better contrast
                    },
                  }}
                >
                  <Typography variant="h6" fontWeight="bold">
                    {service}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default ServiceDetails;
