// src/components/Appointment.js
import React from 'react';
import { Box, Typography, TextField, Button, Grid } from '@mui/material';

const Appointment = () => {
    return (
        <Box sx={{ py: 5, textAlign: 'center' }} id="appointment">
            <Typography variant="h4" gutterBottom>
                Book Appointment
            </Typography>
            <Box component="form" sx={{ mt: 3, maxWidth: 600, mx: 'auto' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField label="Date" type="date" InputLabelProps={{ shrink: true }} fullWidth />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Time" type="time" InputLabelProps={{ shrink: true }} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Full Name" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Phone Number" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Your Message" multiline rows={4} fullWidth />
                    </Grid>
                </Grid>
                <Button variant="contained" color="primary" size="large" sx={{ mt: 3 }}>
                    Make Appointment
                </Button>
            </Box>
        </Box>
    );
};

export default Appointment;
