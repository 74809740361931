const city = "Victoria";

export const services = [
  {
    id: "personal-and-self-care",
    heading: "Personal and self-care",
    thumbnail: "personal-care.jpg",
    banner: "personal-care-banner.jpg",
    title:
      "Our personal care services are delivered with respect and understanding which we believe are just as important as the practical skills and competencies.",
    description:
      "Whether you just need a bit of support to get showered, dressed and ready for your day ahead, or whether you need full support, we’re here to enhance your wellbeing, comfort and confidence and ensure you remain safe in your home.",
    services: [
      "Showering",
      "Bathing",
      "Grooming",
      "Toileting and continence support",
      "Meals and aspiration risks",
      "Mobility support",
      "Hoisting",
      "Enteral feeding / PEG feeding",
      "Bed care",
    ],
    icon: "❤️",
  },
  {
    id: "support-for-daily-activities",
    heading: "Support for daily activities",
    title: "Whatever help you need around the house, we can do it.",
    thumbnail: "daily-support.jpg",
    banner: "daily-support-banner.jpg",
    description:
      "Whether you just need a bit of support to get showered, dressed and ready for your day ahead, or whether you need full support, we’re here to enhance your wellbeing, comfort and confidence and ensure you remain safe in your home.",
    services: [
      "Showering",
      "Bathing",
      "Grooming",
      "Toileting and continence support",
      "Meals and aspiration risks",
      "Mobility support",
      "Hoisting",
      "Enteral feeding / PEG feeding",
      "Bed care",
    ],
    icon: "❤️",
  },
  {
    id: "domestic-assistance",
    heading: "Domestic assistance",
    title: "Whatever help you need around the house, we can do it.",
    banner: "domestic-assistance-banner.jpg",
    thumbnail: "domestic-assistance.jpg",
    description:
      "We can carry out a range of household chores that you’re not able to do for whatever reason. Or we can work with you to support you in organising and undertaking your domestic routines and tasks yourself – helping you remain an active participant in your day-to-day household tasks.",
    services: [
      "Cleaning",
      "Laundry",
      "Household organisation",
      "De-cluttering",
      "Meal preparation and cooking",
      "Shopping and errands",
      "Watering plants"
    ],
    serviceHeading: "Whatever level of support you need from us, we’ll be there to help you keep things the way you like them and ensure a comfortable and safe home environment for you. After all, home is where the heart is.",
    icon: "❤️",
  },
  {
    id: "social-and-community-participation-transition",
    heading: "Social and community participation, Transition",
    title: "Making and maintaining connections within your community can make a huge difference in your life. If this is one of your goals, we can support you.",
    thumbnail: "social.jpg",
    banner: "social-banner.png",
    description:
      "We know that this can mean a lot of different things to different people. Whether it’s finding recreational activities for you to become involved in, developing relationships, researching local services that you need, making and attending appointments, getting out and about to use local amenities, or just going for a coffee in your local café, we can support you according to what you want to achieve.",
  },
  {
    id: "dementia-care-and-support",
    heading: "Dementia care and support",
    thumbnail: "dementia-care.jpg",
    banner: "dementia-care-banner.png",
    title: "Whatever help you need around the house, we can do it.",
    description:
      "We can carry out a range of household chores that you’re not able to do for whatever reason. Or we can work with you to support you in organising and undertaking your domestic routines and tasks yourself – helping you remain an active participant in your day-to-day household tasks.",
    services: [
      "Cleaning",
      "Laundry",
      "Household organisation",
      "De-cluttering",
      "Meal preparation and cooking",
      "Shopping and errands",
      "Watering plants",
    ],
    serviceHeading: "Whatever level of support you need from us, we’ll be there to help you keep things the way you like them and ensure a comfortable and safe home environment for you. After all, home is where the heart is.",
    icon: "❤️",
  },
  {
    id: "full-day-support",
    heading: "24-hour support",
    title: "Your situation is unique and we know one size doesn’t fit all. We take the time to get to know you, that’s how we provide the best quality of care, especially if you need support around the clock.",
    thumbnail: "24x7support.jpg",
    banner: "banner-24x7.png",
    description:
      "No matter how complex your care requirements are, we have qualified support workers, personal carers and nurses to provide a comprehensive range of services and supports.</br></br>Our 24-hour care aims to enhance independence and maximise quality of life with ongoing support any time of the day or night.",
    icon: "❤️",
  },
  {
    id: "companion-care",
    heading: "Companion care",
    title: "We know that there’s so much more to care than ‘tasks’ such as having a shower, getting dressed, cooking and cleaning or medications and other clinical needs. We consider the whole person and ensure our support always focusses on providing friendly company and broader engagement with the things in life that you love.",
    banner: "companion-care-banner.png",
    thumbnail: "companion-care.jpg",
    description:
      "Our carers can be there to provide companionship – creating opportunities and promoting engagement with things you enjoy doing whether it’s hobbies, activities around the home, accessing your community and enjoying outings or just a cup of tea and a chat.",
  },
  {
    id: "in-home-respite",
    heading: "In-home respite",
    thumbnail: "in-home-care.jpg",
    banner: "in-home-care-banner.png",
    title:
      "Our personal care services are delivered with respect and understanding which we believe are just as important as the practical skills and competencies.",
    description:
      "Whether you just need a bit of support to get showered, dressed and ready for your day ahead, or whether you need full support, we’re there to enhance your wellbeing, comfort and confidence and ensure you remain safe in your home.",
    services: [
      "Showering",
      "Bathing",
      "Grooming",
      "Toileting and continence support",
      "Meals and aspiration risks",
      "Mobility support",
      "Hoisting",
      "Enteral feeding / PEG feeding",
      "Bed care",
    ],
    icon: "❤️",
  },
  {
    id: "nursing-care",
    heading: "Nursing Care",
    banner: "nursing-care-banner.jpg",
    thumbnail: "nursing-care.jpg",
    title:
      "All our nurses are handpicked for their experience, and because they share our passion for working in the community.",
    description:
      "Whether you’re recovering from an injury, have a long-term illness or need support managing a chronic condition, in-home nursing gives you the clinical support you need to improve or maintain your health and wellbeing. We are also a registered NDIS provider with a team of dedicated registered nurses and enrolled nurses. Our team can provide home nursing services for NDIS participants, including people with complex care needs.",
    
  },
  {
    id: "palliative-care",
    heading: "Palliative Care",
    banner: "palliative-care-banner.jpg",
    thumbnail: "palliative-care.jpg",
    title: "We understand that palliative care and end-of-life are the most difficult time in the lives of individuals and their families and friends.",
    description: "We support you and your family with meaningful care for your physical and emotional needs during this time.</br></br>We focus on helping you to have the best quality of life, tailoring our supports to your needs. Sometimes this might mean 24/7 bed-care, or pain management. Or it might mean some regular visits to help your family have some respite or it might be supporting you to achieve something you’ve always wanted to.</br></br>We can work closely with your medical team or Palliative Care team to ensure that our supports are integrated into your overall Palliative Care Plan and you receive holistic care.",
    serviceHeading: "Our palliative care and end of life services can include:",
    services: [
      "Pain management",
      "Identifying ways to improve quality of life",
      "Supporting you in things you want to do",
      "All aspects of personal care",
      "Dementia care",
      "Continence management",
    ],
    icon: "❤️",
  }
];
