import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, LinearProgress } from "@mui/material";

const stats = [
  { label: "Happy People", value: 50 },
  { label: "Work Quality", value: 100 },
  { label: "Expert Doctors", value: 5 },
];

const HappyPatients = () => {
  const [progress, setProgress] = useState({});

  useEffect(() => {
    const timer = setTimeout(() => {
      // Initialize progress values for each stat
      const initialProgress = stats.reduce((acc, stat) => {
        acc[stat.label] = stat.value;
        return acc;
      }, {});
      setProgress(initialProgress);
    }, 500); // Delay before progress starts
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box
      sx={{ py: 5, backgroundColor: "#f0f4f8", textAlign: "center" }}
      id="happy-patients"
    >
      <Typography variant="h4" gutterBottom>
        Award Winning Patient Care
      </Typography>
      <Grid container spacing={4} justifyContent="center" sx={{ mt: 2 }}>
        {stats.map((stat, index) => (
          <Grid item xs={6} md={3} key={index}>
            <Typography variant="h3" color="primary">
              {progress[stat.label] || 0}{index === 1 ? "%" : "+"}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={progress[stat.label] || 0}
              sx={{
                height: 10,
                borderRadius: 5,
                backgroundColor: "#e0e0e0",
                mt: 1,
              }}
            />
            <Typography
              variant="subtitle1"
              color="textSecondary"
              sx={{ mt: 1 }}
            >
              {stat.label}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default HappyPatients;
