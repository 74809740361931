import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Button, Drawer, List, ListItem, ListItemText, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../logo-light.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const navItems = [
    { label: 'Home', link: 'home' },
    { label: 'Our Services', link: 'services' },
    { label: 'Book Appointment', link: 'appointment' },
    { label: 'Contact Us', link: 'footer' },
  ];

  const handleNavClick = (link) => {
    if (location.pathname === '/') {
      // On the home page: scroll to section
      scroll.scrollTo(link === 'home' ? 0 : document.getElementById(link).offsetTop, {
        duration: 500,
        smooth: true,
      });
    } else {
      // Not on the home page: navigate to home and scroll after
      navigate('/');
      setTimeout(() => {
        scroll.scrollTo(document.getElementById(link).offsetTop, {
          duration: 500,
          smooth: true,
        });
      }, 100); // Small delay to ensure the page has loaded
    }
    setDrawerOpen(false); // Close drawer if in mobile mode
  };

  return (
    <AppBar position="sticky" sx={{ backgroundColor: '#fff', minHeight: 70 }}>
      <Toolbar sx={{ minHeight: 70, display: 'flex', alignItems: 'center' }}>
        {/* Logo */}
        <Box component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          <img src={logo} alt="Logo" style={{ width: 80, height: 80, marginRight: 10 }} />
          <img src="/ndis-logo.png" alt="NDIS Logo" style={{ width: 100, height: 80 }} />
        </Box>

        {/* Desktop Navigation Items */}
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          {navItems.map((item) => (
            <Button
              color="inherit"
              key={item.label}
              onClick={() => handleNavClick(item.link)}
              sx={{ color: '#2a5c82' }}
            >
              <b>{item.label}</b>
            </Button>
          ))}
        </Box>

        {/* Mobile Menu Icon */}
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer(true)}
          sx={{ display: { xs: 'block', md: 'none' } }}
        >
          <MenuIcon />
        </IconButton>

        {/* Drawer for Mobile Navigation */}
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          sx={{ '& .MuiDrawer-paper': { backgroundColor: '#86ad3a' } }}
        >
          <List>
            {navItems.map((item) => (
              <ListItem button key={item.label} onClick={() => handleNavClick(item.link)}>
                <ListItemText primary={item.label} sx={{ color: '#2a5c82' }} />
              </ListItem>
            ))}
          </List>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
