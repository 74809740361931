// src/components/Footer.js
import React from 'react';
import { Box, Typography, Grid, Link, IconButton } from '@mui/material';
import { Instagram, Facebook, WhatsApp, Call } from '@mui/icons-material';

const Footer = () => {
    return (
        <Box sx={{ py: 5, backgroundColor: '#2a5c82', color: '#fff', position: 'relative' }} id="footer">
            <Grid container spacing={3} justifyContent="center">
                
                {/* Location Section with Google Maps Iframe */}
                <Grid item xs={12} md={4}>
                    <Typography variant="h6" gutterBottom>
                        Our Location
                    </Typography>
                    <Box sx={{ borderRadius: 2, overflow: 'hidden', boxShadow: 1 }}>
                        <iframe
                            title="Location Map"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3156.016976410322!2d144.87276027522412!3d-37.71927937199945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65bfd044c9969%3A0xbe13d9d330d90da9!2s159%20Parer%20Rd%2C%20Airport%20West%20VIC%203042%2C%20Australia!5e0!3m2!1sen!2sin!4v1731159778695!5m2!1sen!2sin"
                            width="100%"
                            height="250"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                        ></iframe>
                    </Box>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography variant="h6" gutterBottom>
                        Contact Us
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="body2">Csquare Care Pty Ltd</Typography>
                        <Typography variant="body2">
                            Mobile No: <Link href="tel:+61433650188" color="inherit">+61 433 650 188</Link>
                        </Typography>
                        <Typography variant="body2">Address: 159 Parer Road, Airport West VIC 3042</Typography>
                        <Box sx={{ mt: 2 }}>
                            <Link href="https://www.facebook.com" target="_blank" color="inherit" underline="hover">
                                <IconButton color="inherit">
                                    <Facebook />
                                </IconButton>
                            </Link>
                            <Link href="https://www.instagram.com" target="_blank" color="inherit" underline="hover">
                                <IconButton color="inherit">
                                    <Instagram />
                                </IconButton>
                            </Link>
                        </Box>
                    </Box>
                </Grid>

                {/* Other sections can be added here if needed */}
            </Grid>

            {/* Floating WhatsApp and Call Icons */}
            <IconButton
                href="https://wa.me/+61433650188" // WhatsApp link with phone number
                target="_blank"
                sx={{
                    position: 'fixed',
                    bottom: 80,
                    right: 20,
                    backgroundColor: '#25D366',
                    color: '#fff',
                    '&:hover': { backgroundColor: '#1ebe57' },
                }}
            >
                <WhatsApp />
            </IconButton>

            <IconButton
                href="tel:+61433650188" // Direct call link with phone number
                sx={{
                    position: 'fixed',
                    bottom: 20,
                    right: 20,
                    backgroundColor: '#34b7f1',
                    color: '#fff',
                    '&:hover': { backgroundColor: '#28a0ce' },
                }}
            >
                <Call />
            </IconButton>

            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                © 2024 CSquare. All rights reserved.
            </Typography>
        </Box>
    );
};

export default Footer;
