import React from "react";
import { Box, Card, CardContent, Typography, Grid } from "@mui/material";
import HighQualityIcon from "@mui/icons-material/HighQuality";
import HandshakeIcon from "@mui/icons-material/Handshake";
import SupportIcon from "@mui/icons-material/Support";

const InfoCardsSection = () => (
  <Box sx={{ padding: "40px", backgroundColor: "#f5f5f5" }} id="info-cards">
    {/* Section Heading */}

    <Grid
      container
      spacing={4}
      justifyContent="center"
      sx={{ marginTop: "-190px !important" }}
    >
      <Grid item xs={12}>
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            color: "#2a5c82",
            fontSize: "42px",
          }}
        >
          We Believe in?
        </Typography>
      </Grid>
      {/* Card 1: Online Appointment */}
      <Grid item xs={12} md={4}>
        <Card
          sx={{
            textAlign: "center",
            padding: "20px",
            borderRadius: "16px",
            boxShadow: 3,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            transition: "transform 0.3s ease, box-shadow 0.3s ease",
            "&:hover": {
              transform: "scale(1.05)",
              boxShadow: 6,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SupportIcon fontSize="large" sx={{ color: "#2a5c82" }} />
          </Box>
          <CardContent sx={{ flexGrow: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: "bold", mt: 1 }}>
              Person Centred Support
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              className="text-justify"
            >
              We prioritize the individual needs and preferences of each
              participant, ensuring personalized and effective support. Our
              approach is centred on understanding and respecting each person's
              unique circumstances and goals.
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      {/* Card 2: Working Hours */}
      <Grid item xs={12} md={4}>
        <Card
          sx={{
            textAlign: "center",
            padding: "20px",
            borderRadius: "16px",
            boxShadow: 3,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            transition: "transform 0.3s ease, box-shadow 0.3s ease",
            "&:hover": {
              transform: "scale(1.05)",
              boxShadow: 6,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <HandshakeIcon fontSize="large" sx={{ color: "#2a5c82" }} />
          </Box>
          <CardContent sx={{ flexGrow: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: "bold", mt: 1 }}>
              Strength Based Support
            </Typography>
            <Typography variant="body2" color="textSecondary">
              We believe in identifying and leveraging the unique strengths of
              each participant. We support our participants to build confidence
              and achieve their personal goals.
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      {/* Card 3: Emergency Cases */}
      <Grid item xs={12} md={4}>
        <Card
          sx={{
            textAlign: "center",
            padding: "20px",
            borderRadius: "16px",
            boxShadow: 3,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            transition: "transform 0.3s ease, box-shadow 0.3s ease",
            "&:hover": {
              transform: "scale(1.05)",
              boxShadow: 6,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <HighQualityIcon fontSize="large" sx={{ color: "#2a5c82" }} />
          </Box>
          <CardContent sx={{ flexGrow: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: "bold", mt: 1 }}>
              Quality Support
            </Typography>
            <Typography variant="body2" color="textSecondary">
              We provide high-quality support to individuals with disabilities.
              Our commitment to excellence ensures that every participant
              receives the best support tailored to their unique needs,
              fostering a supportive and empowering environment.
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </Box>
);

export default InfoCardsSection;
