// HeroSection.js
import React from 'react';
import { Box, Typography } from '@mui/material';

const HeroSection = () => (
  <Box
    sx={{
      height: '60vh',
      backgroundImage: 'url(/banner.png)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'left',
      color: '#2a5c82',
      textAlign: 'left',
      padding: 5
    }}
  >
    <Typography variant="h3" component="h1" gutterBottom>
      NDIS Disability Support
    </Typography>
    <Typography variant="body1" sx={{ maxWidth: '600px', mb: 3 }}>
      We are a registered NDIS service provider
    </Typography>
  </Box>
);

export default HeroSection;
